import { Container } from '@mui/material'
import FirmInfoContainer from '~/src/components/firms/FirmInfoContainer'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const FirmInfo = () => {
  const pageName: keyof typeof RoutesEnum = 'FIRM_INFO'
  const theme = useCustomMuiTheme()

  useLayout('firmInfos')

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>

      <Container sx={{
        mt: 4,
        p: 4,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows
      }}>
        <FirmInfoContainer />
      </Container>
    </SlideFromRight>
  )
}

export default FirmInfo
